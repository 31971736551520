@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Comfortaa', cursive;
}

img{
  width:100%;
}